.profile_img_title {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.profile_img {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 50%;
    border: solid 1px #353434;
    border-radius: 50%;
    box-shadow: 0 5px 10px 5px rgba(145, 139, 139, 0.5);
}

.change_settings_btn {
    color: white;
    background-color: #b6122d;
    border-color: #D90429;
    margin: 5px; 
}

.hide {
    display: none;
}

.show {
    display: block;
}