.profile_card {
    text-align: center;
}

.profile_card_img {
    display: block;
    /* margin-left: auto;
    margin-right: auto; */
    margin: auto;
    width: 70%;
    height: 100%;
    border: solid 1px #353434;
    border-radius: 50%;
}

.profile_card_img_div {
    justify-items: center;
    height: 225px;
}

.username_title {
    display: block;
    margin: auto;
    font-weight: bold;
    font-size: 2.5rem;
}

.info_icon {
    margin-left: 90%;
    margin-top: 5%;
    color: #353434;
}

.info_icon:hover {
    color: #cc0000;
}

.user_location_text {
    display: block;
    margin: auto;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 25px;
}

.followers_info, .following_info {
    font-weight: bold;
}

@media (max-width: 768px) {
    .profile_card_img {
        width: 55%;
    }
}