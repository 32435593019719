.signin_img {
    width: 100%;
    height: 100%;
}

.loginForm {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.signin_title_div, .signin_btn_div {
    width: 90%;
    display: block;
    margin: auto;
}

.signin_title {
    float: left;
}

.signup_atag{
    color: #ff0000;
    float: right;
    font-weight: bold;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 10px;
}

.signup_atag:hover {
    color: #ff0000;
}


.form-group {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
}

.email_group {
    margin-top: 80px;
}

.signin_btn {
    float: left;
    background-color: #ff0000;
    color: #ffffff;
    border: #f87b7b 2px solid;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.alert_text {
    float: right;
    color: #855600;
}

small {
    clear: both;
}